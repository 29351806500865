var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-card', {
    attrs: {
      "shadow": "never"
    }
  }, [_c('div', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', [_vm._v("List of suppliers invited to this tender.")]), _c('el-radio-group', {
    model: {
      value: _vm.radioPosition,
      callback: function ($$v) {
        _vm.radioPosition = $$v;
      },
      expression: "radioPosition"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": "active"
    }
  }, [_vm._v("Active")]), _c('el-radio-button', {
    attrs: {
      "label": "pending"
    }
  }, [_vm._v(" Pending "), _c('el-badge', {
    staticStyle: {
      "position": "absolute",
      "top": "0",
      "right": "-5px",
      "z-index": "1"
    },
    attrs: {
      "value": _vm.totalPendingOrganisation
    }
  })], 1)], 1)], 1), _c('RftSuppliersActive', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.radioPosition === 'active',
      expression: "radioPosition === 'active'"
    }],
    ref: "RftSuppliersActive"
  }), _c('RftSuppliersPending', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.radioPosition === 'pending',
      expression: "radioPosition === 'pending'"
    }],
    ref: "RftSuppliersPending",
    model: {
      value: _vm.totalPendingOrganisation,
      callback: function ($$v) {
        _vm.totalPendingOrganisation = $$v;
      },
      expression: "totalPendingOrganisation"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }